export const cleanEmptyObjectKeys = <T>(obj: T): T => {
  for (const propName in obj) {
    const value = obj[propName] as any
    if ([null, undefined, ''].includes(value)) {
      delete obj[propName]
    }
  }
  return obj
}

export async function sleepMs(timeMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y
}

export const formatNumberToCurrency = (currency: string, n: number): string => {
  if (currency && currency === '$' && n > 0) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(n)
  }
  return currency + n?.toFixed(2)
}

export const range = (start: number, stop: number, step: number): Array<number> => {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
}

export const numberToCurrency = (number: number, currency: string): string => {
  if (currency && currency === '$' && Number.isFinite(number)) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })
      .format(number)
      .replace('$', '')
  } else if (currency && currency === '€' && Number.isFinite(number)) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 })
      .format(number)
      .replace('€', '')
  }

  return number && number.toFixed(2)
}
