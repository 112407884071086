/**
 * Generate a Universally Unique Identifier (UUID)
 * @see https: //stackoverflow.com/a/2117523/38375
 * @returns {string} the generated UUID
 */
export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function closeOnClickOutside(element: Element, cb: () => void): void {
  document.addEventListener(
    'click',
    (evt) => {
      if (evt.target !== element && !element.contains(evt.target as Element)) {
        cb()
      }
    },
    { capture: true }
  )
}

export function range(min: number, max: number, step = 1): number[] {
  let arraySize = Math.floor((max - min) / step)
  if (arraySize < 0) arraySize = 0
  return new Array(arraySize).fill(0).map((_, index) => index * step + min)
}

export function removePageScroll(): void {
  document.body.classList.add('remove-page-scroll')
}

export function addPageScroll(): void {
  document.body.classList.remove('remove-page-scroll')
}
